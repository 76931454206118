import React, { Component } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CropIcon from '@mui/icons-material/Crop';

export default class Ruler extends Component {
    
    constructor(props) {
        super(props);
    }
    
    render() {
      let factor = this.props.docWidth / this.props.viewPort?.containerWidth;
      let rulerLinesH = [];
      let sc = 10;
      if (this.props.viewPort?.zoomFactor > 0.45)
        sc = 5;
      if (this.props.viewPort?.zoomFactor > 1)
        sc = 2;
      for (let i = 0; i < Math.floor(this.props.docWidth / sc) + 1; i++) {
        rulerLinesH.push(i * sc);
      }
      let rulerLinesV = [];
      for (let i = 0; i < Math.floor(this.props.docHeight / sc) + 1; i++) {
        rulerLinesV.push(i * sc);
      }
      if (this.props.viewPort?.zoomFactor <= 0.3)
        sc = 20;
      if (this.props.viewPort?.zoomFactor <= 0.15)
        sc = 40;

      return(
        <div className={"ruler " + this.props.orientation + " sc-" + sc}>
            { this.props.orientation == "horizontal" && <div className="slider" style={{
                left: (this.props.viewPort?.left * -1 * this.props.viewPort?.zoomFactor) || 0,
                width: (this.props.docWidth * this.props.viewPort?.zoomFactor / factor) || 0
            }}>
                <svg viewBox={"0 0 " + this.props.docWidth + " 20"}>
                    {
                        rulerLinesH.map((line, i) => 
                            <line key={i} x1={line} x2={line} y1={0} y2={20} stroke='black' strokeWidth={(0.1 / this.props.viewPort?.zoomFactor) || 0.1} />
                        )
                    }
                </svg>
                <div className="nums horizontal">{ rulerLinesH.map((line, i) => <span key={i} className="num" style={{left: "calc(" + ((line / this.props.docWidth * 100) || 0) + "% - 10px)" }}>{line}</span>) }</div>
            </div> }
            { this.props.orientation == "vertical" && <div className="slider" style={{
                top: (this.props.viewPort?.top * -1 * this.props.viewPort?.zoomFactor) || 0,
                height: (this.props.docHeight * this.props.viewPort?.zoomFactor / factor) || 0
            }}>
                <svg viewBox={"0 0 20 " + this.props.docHeight}>
                    {
                        rulerLinesV.map((line, i) => 
                            <line key={i} x1={0} x2={20} y1={line} y2={line} stroke='black' strokeWidth={(0.1 / this.props.viewPort?.zoomFactor) || 0.1} />
                        )
                    }
                </svg>
                <div className="nums vertical">{ rulerLinesV.map((line, i) => <span key={i} className="num" style={{top: "calc(" + ((line / this.props.docHeight * 100) || 0) + "% - 13px)" }}>{line}</span>) }</div>
            </div> }
        </div>
        );
      }
}