import React, { Component } from 'react';
import { Popper } from '@mui/base/Popper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SellIcon from '@mui/icons-material/Sell';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default class PriceBox extends Component {
    
    constructor(props) {
        super(props);
    }
    
    render() {
        let ids = Object.keys(this.props.priceStats || {});
        ids.sort();
      return(
            <Popper placement="bottom-end" open={!!this.props.open} anchorEl={this.props.pricingAnchor}>
                <div
                    style={{
                        padding: '0.5rem 1rem',
                        border: '1px solid',
                        borderColor: 'rgba(0,0,0,0.2)',
                        margin: '0.5rem',
                        borderRadius: '0.5rem',
                        boxShadow: '0 2px 8px 0 rgba(0,0,0,0.1)',
                        width: 500,
                        background: 'white',
                        maxHeight: 'calc(100vh - 170px)',
                        overflow: 'auto'
                    }}
                >
                <Stack direction="row">
                    <Typography variant="h5" gutterBottom><SellIcon fontSize='small' />&nbsp;Pricing</Typography>
                    <Box sx={{flex: 1, textAlign: 'right'}}>
                        <IconButton onClick={this.props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Stack>
                <Box>
                    <Typography variant="h6" gutterBottom>Area usage</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell><span className="legend bbox"></span>Total area of the cut-out:</TableCell>
                                    <TableCell>{this.props.totalArea} units</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><span className="legend bbox"></span>Price for the cut-out:</TableCell>
                                    <TableCell><b>{(this.props.totalArea * this.props.price).toFixed(2)},-</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><span className="legend rect"></span>Area used in the cut-out:</TableCell>
                                    <TableCell>{this.props.usedArea} units ({Math.round((this.props.usedArea / this.props.totalArea) * 100)} %)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><span className="legend waste"></span>Money wasted on empty space:</TableCell>
                                    <TableCell>{this.props.wasted?.toFixed(2)},-</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br /><Typography variant="h6" gutterBottom>Per unit</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                {
                                    ids.map(id => {
                                        return <React.Fragment key={id}>
                                        <TableRow className={this.props.priceStats[id].cnt > 1 ? "extra-detail" : ""}>
                                            <TableCell>Price for 1× {id}:</TableCell><TableCell>{this.props.priceStats[id].one.toFixed(2)},-</TableCell>
                                        </TableRow>
                                        {this.props.priceStats[id].cnt > 1 &&
                                            <TableRow>
                                                <TableCell>Price for {this.props.priceStats[id].cnt}× {id}:</TableCell><TableCell>{this.props.priceStats[id].all.toFixed(2)},-</TableCell>
                                            </TableRow>
                                        }
                                        </React.Fragment>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                </div>
            </Popper>
        );
      }
}