export default class ForBinResolver {
    
    recompute(props)
    {
        const ab = props.allBox;
        const bb = props.boundingBox;
        const sp = props.spacing;
        const price = props.price;
        const data = props.data;
        const br = props.border;
        const aw = ab.w + sp;
        const ah = ab.h + sp;

        let usedArea = 0;
	    let totalArea = aw * ah;

        for (let i = 0; i < data.length; i++) {
            let w = data[i].w - sp;
            let h = data[i].h - sp;
            usedArea += (w * h);
        }
        let wasted = (totalArea - usedArea) * price;
        let priceStats = {};
        let rects = [];
        for (let i = 0; i < data.length; i++) {
        
            let x = data[i].x + sp + br;
            let y = data[i].y + sp + br;
            let w = data[i].w - sp;
            let h = data[i].h - sp;		
            
            if (!priceStats[data[i].parId])
            {
                let s = w * h;
                let p = (s / usedArea) * (totalArea * price);
                priceStats[data[i].parId] = { one: p, all: 0, cnt: 0 };
            }
            priceStats[data[i].parId].all += priceStats[data[i].parId].one;
            priceStats[data[i].parId].cnt += 1;

            rects.push({ x: x, y: y, w: w, h: h, id: data[i].id, parId: data[i].parId,
                t1: data[i].id, t2: Math.round(priceStats[data[i].parId].one) + ",-" });
        }

        return {
            "ab": ab,
            "bb": bb,
            "sp": sp,
            "price": price,
            "aw": aw,
            "ah": ah,
            "rects": rects,
            "solvedRects": data,
            "totalArea": totalArea,
            "usedArea": usedArea,
            "wasted": wasted,
            "priceStats": priceStats
        };
    }

    rectanglesOverlap(rect1, rect2) {
        return !(rect1.x + rect1.w <= rect2.x || // rect1 is to the left of rect2
                 rect2.x + rect2.w <= rect1.x || // rect2 is to the left of rect1
                 rect1.y + rect1.h <= rect2.y || // rect1 is above rect2
                 rect2.y + rect2.h <= rect1.y);  // rect2 is above rect1
    }

    checkClipping(rectangles) {
        for (let i = 0; i < rectangles.length; i++) {
            for (let j = i + 1; j < rectangles.length; j++) {
                if (this.rectanglesOverlap(rectangles[i], rectangles[j])) {
                    return false;
                }
            }
        }
        return true;
    }

    findBoundingBox(rectangles) {
        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;

        if (rectangles && rectangles.length && rectangles.forEach) {
            rectangles?.forEach(rect => {
                minX = Math.min(minX, rect.x);
                minY = Math.min(minY, rect.y);
                maxX = Math.max(maxX, rect.x + rect.w);
                maxY = Math.max(maxY, rect.y + rect.h);
              });
            return { x: minX, y: minY, w: maxX - minX, h: maxY - minY };
        }
        return null;
      }

    async solveAndFindBest(api, axios, docWidth, docHeight, spacing, border, origRects, price, selectedAlgos)
    {
        if (selectedAlgos.length && origRects?.length) {
            let minArea = Number.MAX_VALUE;
            let best = null;
            for (const algo of selectedAlgos) {
                console.log("Running algorithm: " + algo);
                const result = await this.solve(api, axios, docWidth, docHeight, spacing, border, origRects, price, algo)
                console.log(" - Result: ", result);
                const used = (result?.computedData?.totalArea || Number.MAX_VALUE);
                if (used < minArea && this.checkClipping(result?.computedData?.rects)) {
                    minArea = used;
                    best = result;
                    best.bestAlgo = algo;
                }
            }
            return best;
        } else {
            return await this.solve(api, axios, docWidth, docHeight, spacing, border, [], price, "")
        }
    }

    manualSolve(docWidth, docHeight, spacing, border, data, price)
    {
        let area = {
            "w": docWidth - (spacing) - (border * 2),
            "h": docHeight - (spacing) - (border * 2)
        }
        let bbox = this.findBoundingBox(data);
        bbox.w += (border * 2);
        bbox.h += (border * 2);
        let allbox = {...bbox};
        allbox.w = area.w;
        allbox.x = 0;
        console.log("Running manual recompute");
        const x = this.recompute({
            allArea: { "w" : area.w + spacing, "h": area.h + spacing},
            allBox: allbox,
            boundingBox: bbox,
            spacing: spacing,
            price: price,
            data: data || [],
            border: border
        });

        let result = {
            allArea: { "w" : area.w + spacing, "h": area.h + spacing},
            computedData: x,
            boundingBox: bbox,
            allBox: allbox,
        };

        console.log(" - Result: ", result);
        return result;
    }

    async solve(api, axios, docWidth, docHeight, spacing, border, origRects, price, selectedAlgo)
    {
        let area = {
            "w": docWidth - (spacing) - (border * 2),
            "h": docHeight - (spacing) - (border * 2)
        }

        let rects = [];
        for (var i = 0; i < origRects.length; i++)
        {
            var r = origRects[i];
            var cnt = Number(r.cnt);
            for (var j = 0; j < cnt; j++) {
                var x = "";
                if (cnt > 1)
                    x = "-" + (j+1);
                const re = {
                    id: r.id + x,
                    parId: r.id,
                    w: Number(r?.w || 0) + spacing,
                    h: Number(r?.h || 0) + spacing,
                    x: Number(r?.x || 0),
                    y: Number(r?.y || 0)
                };
                rects.push(re);
            }
        }

        rects.sort((a, b) => ((a.w > b.w) && -1) || (((a.w < b.w) && 1) || (((a.h > b.h) && -1) || 1)));

        const data = {
            "rectangles" : rects,
            "area" : area,
            "algorithm" : selectedAlgo
        };

        try {
            let bbox = [];
            let cdata = [];
            if (rects.length) {
                const response = await axios.post(api + '/solve', data);
                cdata = response.data;
                /*cdata = this.doSolve();*/
                bbox = this.findBoundingBox(cdata);
            }
            if (bbox?.w && bbox?.h) {
                bbox.w = (bbox?.w || 0) + (border * 2);
                bbox.h = (bbox?.h || 0) + (border * 2);
            }
            
            let allbox = {...bbox};
           // Let's always go from top to bottom instead:
           allbox.w = area?.w || 0;
           allbox.x = 0;

            const x = this.recompute({
                allArea: { "w" : (area?.w || 0) + spacing, "h": (area?.h || 0) + spacing},
                allBox: allbox,
                boundingBox: bbox,
                spacing: spacing,
                price: price,
                data: cdata || [],
                border: border
            });

            return {
                allArea: { "w" : (area?.w || 0) + spacing, "h": (area?.h || 0) + spacing},
                computedData: x,
                boundingBox: bbox,
                allBox: allbox,
            };

        } catch (error) {
            console.error(error);
            return null;
        }
    }
    
}