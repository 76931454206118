/*require('../algorithms/columnRowOptimizer');
require('../algorithms/guillotine_area');
require('../algorithms/guillotine_avg');
require('../algorithms/guillotine_baf');
require('../algorithms/guillotine_blsf');
require('../algorithms/guillotine_bruteforce10_area');
require('../algorithms/guillotine_bruteforce10_ymin');
require('../algorithms/guillotine_bruteforce100_ymin');
require('../algorithms/guillotine_bssf');
require('../algorithms/guillotine_xmin');
require('../algorithms/guillotine_ymin');
require('../algorithms/rbp_baf');
require('../algorithms/rbp_blr');
require('../algorithms/rbp_blsf');
require('../algorithms/rbp_bssf');
require('../algorithms/rbp_cpr');*/

export default class MiniServer {

    algoList = [
        "columnRowOptimizer",
        "guillotine_area",
        "guillotine_avg",
        "guillotine_baf",
        "guillotine_blsf",
        "guillotine_bruteforce10_area",
        "guillotine_bruteforce10_ymin",
        "guillotine_bruteforce100_ymin",
        "guillotine_bssf",
        "guillotine_xmin",
        "guillotine_ymin",
        "rbp_baf",
        "rbp_blr",
        "rbp_blsf",
        "rbp_bssf",
        "rbp_cpr"
    ];

    // Cache, leave empty:
    algorithms = {};
    algoModules = [];

    reloadAlgorithms()
    {
        console.log("Reloading algorithm cache.");
        
        this.algorithms = {};
        this.algoModules = [];
        
        const p = process.env.PUBLIC_URL + "../algorithms/";
        this.algoList.forEach((file) => {
            try {            
                var cls = require(p + file + ".js");
                if (cls) {
                    var obj = new cls;
                    if (obj) {
                        var name = obj.getName();
                        var key = cls.name;
                        console.log("Found algorithm: [" + key + "] " + name + " (" + file + ")");
                        if (key == "ExampleAlgorithm")
                            console.log(" --> Ignoring the Example Algorithm.");
                        else {
                            this.algorithms[key] = obj;
                            this.algoModules.push(p + file);
                            console.log(" --> Imported.");
                        }
                    } else {
                        console.error("Could not import algorithm from: " + file + " - could not create instance.");
                    }
                } else {
                    console.error("Could not import algorithm from: " + file + " - no class exported.");
                }
            } catch (ex) { console.error("Could not import algorithm from: " + file + " - generic error.", ex); }
        });
    }

    getAlgorithms()
    {
        if (this.algoModules.length <= 0)
            this.reloadAlgorithms();

        var arr = {};
    
        for (const [key, value] of Object.entries(this.algorithms)) {
            arr[key] = value.getName();
        }

        return arr;
    }


}