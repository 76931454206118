import React, { Component } from 'react';
import RectComp from './RectComp';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';

export default class RectCompList extends Component {

    genericDrawings = [
        {
            "name" : "Generic kiosk",
            "width": 102,
            "height": 198,
        },
        {
            "name" : "Large kiosk",
            "width": 204,
            "height": 396,
        }
    ];

    constructor(props) {
        super(props);

        this.state = {
            myDrawings : [
                {
                    "name" : "My amazing schema",
                    "width": 200,
                    "height": 100,
                },
                {
                    "name" : "Another schema - kiosks outside",
                    "width": 100,
                    "height": 50,
                },
                {
                    "name" : "I like trains",
                    "width": 40,
                    "height": 40,
                },
                {
                    "name" : "Tablet schema for Likos",
                    "width": 80,
                    "height": 50,
                }
            ],
            choosingDrawing : false,
            openMyDrawings: true,
            openGenericDrawings: false
        }
        
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);
        this.change = this.change.bind(this);
        this.addDrawing = this.addDrawing.bind(this);
        this.openDrawingMenu = this.openDrawingMenu.bind(this);
    }
    
    remove(index) {
        var arr = this.props.rects;
        arr.splice(index, 1);
        this.props.onChange(arr);
    }

    change(index, id, w, h, cnt) {
        var arr = this.props.rects;
        if (arr[index].id !== id || arr[index].w !== w || arr[index].h !== h || arr[index].cnt !== cnt)
        {
            arr[index].id = id;
            arr[index].w = w;
            arr[index].h = h;
            arr[index].cnt = cnt;
            this.props.onChange(arr);
        }
    }   
    
    add() {
        var arr = this.props.rects;
        arr.push(
           {id: "R" + arr.length, w: 200, h: 200, cnt: 1, key: Date.now()}
        );
        this.props.onChange(arr);
    }

    addDrawing(r) {
        var arr = this.props.rects;
        arr.push(
           {id: r.name, w: r.width, h: r.height, cnt: 1, key: Date.now(), isTechnicalDrawing: true}
        );
        this.props.onChange(arr);
        this.setState({ choosingDrawing: false });
    }

    openDrawingMenu() {
        this.setState({choosingDrawing : true});
    }

    icoRect(obj) {
        let pr = 0;
        if (obj.width > obj.height) {
            pr = 40 / obj.width;
        } else {
            pr = 40 / obj.height;
        }
        let w = obj.width * pr;
        let h = obj.height * pr;
        return { x: 5 + ((40 - w) / 2), y: 5 + ((40 - h) / 2), w: w, h: h };
    }
    
    render() {
        let listItems = this.props.rects.map((item, index) => (
            <RectComp
                key={item.key}
                id={item.id} w={item.w} h={item.h} cnt={item.cnt} isTechnicalDrawing={item.isTechnicalDrawing}
                onRemove={()=>this.remove(index)}
                onChange={(obj)=>{
                    this.change(index,obj.id,obj.w,obj.h,obj.cnt);
                }}
            />
        ));
        return (
           <div>
            <Box>
                {listItems}
            </Box>
            <Box sx={{marginTop: 2, marginBottom: 2, textAlign: 'center'}}>
                <Button variant="contained" startIcon={<AddIcon />} onClick={this.openDrawingMenu}>Add technical drawing</Button>&nbsp;
                <Button variant="outlined" startIcon={<AddIcon />} onClick={this.add}>Add custom region</Button>
            </Box>

            <Dialog
                open={!!this.state.choosingDrawing}
                onClose={() => { this.setState({choosingDrawing: false}) }}
            >
                <DialogTitle>
                    Add technical drawing
                </DialogTitle>
                <DialogContent sx={{ width: "400px", maxHeight: "600px"}}>
                        <List
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                            component="nav"
                        >
                            <ListItemButton onClick={() => { this.setState({openGenericDrawings : !this.state.openGenericDrawings}) }}>
                                <ListItemIcon>
                                    <FolderSpecialIcon />
                                </ListItemIcon>
                                <ListItemText primary="Generic drawings" />
                                {this.state.openGenericDrawings ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={this.state.openGenericDrawings} timeout="auto" unmountOnExit>
                                {
                                    this.genericDrawings.map((dr, i) => {
                                        let ico = this.icoRect(dr);
                                        return <ListItemButton sx={{ pl: 4 }} key={i} onClick={() => { this.addDrawing(dr); }}>
                                            <ListItemIcon>
                                                <svg viewBox="0 0 50 50" style={{"marginRight": "10px"}}>
                                                    <rect x={0} y={0} width={50} height={50} className="demo doc" />
                                                    <rect x={ico.x} y={ico.y} width={ico.w} height={ico.h} className="demo rect" />
                                                </svg>
                                            </ListItemIcon>
                                            <ListItemText primary={dr.name} secondary={dr.width + " × " + dr.height } />
                                        </ListItemButton>
                                    })
                                }
                            </Collapse>

                            <ListItemButton onClick={() => { this.setState({openMyDrawings : !this.state.openMyDrawings}) }}>
                                <ListItemIcon>
                                    <FolderSharedIcon />
                                </ListItemIcon>
                                <ListItemText primary="My personal drawings" />
                                {this.state.openMyDrawings ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={this.state.openMyDrawings} timeout="auto" unmountOnExit>
                            {
                                this.state.myDrawings.map((dr, i) => {
                                    let ico = this.icoRect(dr);
                                    return <ListItemButton sx={{ pl: 4 }} key={i} onClick={() => { this.addDrawing(dr); }}>
                                        <ListItemIcon>
                                            <svg viewBox="0 0 50 50" style={{"marginRight": "10px"}}>
                                                <rect x={0} y={0} width={50} height={50} className="demo doc" />
                                                <rect x={ico.x} y={ico.y} width={ico.w} height={ico.h} className="demo rect" />
                                            </svg>
                                        </ListItemIcon>
                                        <ListItemText primary={dr.name} secondary={dr.width + " × " + dr.height } />
                                    </ListItemButton>
                                })
                            }
                            </Collapse>
                        </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({choosingDrawing: false}) }}>Cancel</Button>
                </DialogActions>
            </Dialog>
           </div>
        );
    }
}
